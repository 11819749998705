@import url('https://fonts.googleapis.com/css?family=Poppins:700|Poppins:600|Poppins:500|Poppins:400|Poppins:300');
@import url('https://fonts.googleapis.com/css?family=Inter:800|Inter:700|Inter:600|Inter:500|Inter:400');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');

* {
  box-sizing: border-box;
  color: #070817;
  font-family: Poppins;
  background-color: transparent;
  margin: 0px;
}


.App {
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}

.content {
  margin-left: auto; /* Usar margen automático para centrar */
  margin-right: auto; /* Usar margen automático para centrar */
  text-align: center; /* Centrar texto horizontalmente si es necesario */
  padding: 0px;
}

/* NAVBAR ------------------------------------------------------------------------------------------------- */


.navbar-content {
  background-color: #ffff;
}

.navbar {
  top: 0;
  z-index: 1000;
  background-color: #FFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.01);
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 70px;
}

.links {
  margin-left: 200px;
  display: flex;
  align-items: center; /* Centra verticalmente los enlaces */
  flex: 1; /* Ocupa el espacio restante */
  justify-content: center; /* Centra los enlaces horizontalmente */
}

.links a {
  position: relative;
  margin: 0 20px; /* Espacio entre enlaces */
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #070817;
  transition: color 0.3s;
}

.links a:hover {
  color: #51C764;
}


.logo {
  max-width: 100%;
  height: auto;
  max-height: 38px;
  background-color: white;
  cursor: pointer;
  margin-right: auto;
}

.logo-link {
  background-color: transparent;
}

.cta {
  background-color: transparent;
}


.header-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
}




/* Mobile styles */
.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-left: 20px;
}

.hamburger {
  width: 20px;
  height: 2px;
  background-color: #333;
  margin: 3px;
  transition: 0.4s;
  border-radius: 40px;
}

.hamburger.open {
  background-color: #51C764;
}

@media (max-width: 1000px) {
  .navbar {
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
    position: fixed;
    width: 100%;
    border-bottom: 0.5px solid #ddd;
  }

  .links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #ffff;
    text-align: center;
    transition: max-height 0.5s ease-in-out, transform 0.5s ease-in-out;
    overflow: hidden;
    max-height: 0;
    transform: translateY(-100%);
    position: fixed;
    top: 70px; /* Adjust if needed based on navbar height */
    left: 0;
    right: 0;
    z-index: 999;
    margin-left: 0px;
    padding-top: 150px;
  }

  .links.open {
    display: flex;
    max-height: 100vh; /* 75% of the viewport height */
    transform: translateY(0);
  }

  .links a {
    margin: 10px 0;
    font-size: 18px;
  }

  .header-button {
    border-radius: 30px;
    padding: 10px;
    margin-top: 10px;
    height: 55px;
    width: 90px;
    border-radius: 90px;

    color: #5080ed;
    background-color: #507fed24;
    box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.07);
    transition: background-color 0.3s;
  }

  .header-buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 300px;
    width: 100%;
  }

  .menu-icon {
    display: flex;
  }

  .cta {
    display: none;
  }

  .logo {
    max-width: 100%;
    height: auto;
    max-height: 27px;
    background-color: white;
    cursor: pointer;
    margin-right: auto;
  }
}


/* HOME ------------------------------------------------------------------------------------------------- */

.home {
  text-align: center;
  padding-top: 60px;
  background-color: #fbfbfe;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 600px;
  margin-left: 0px;
}

.home h1 {
  font-size: 50px;
  font-weight: 900;
  line-height: 1.2;
}

.titulo-destacado {
  color: #51C764;
  background-clip: text;
  margin-left: 0;
}

.home p {
  font-size: 18px;
  margin-top: 40px;
  white-space: pre-line;
  line-height: 1.5;
  width: 100%; /* Cambia esto para que ocupe todo el ancho del contenedor */
  max-width: 500px; /* Limita el ancho máximo */
  margin-left: auto; /* Centra horizontalmente */
  margin-right: auto; /* Centra horizontalmente */
  text-align: center; /* Asegura que el texto esté centrado dentro del párrafo */
}

@media (max-width: 1000px) {
  .home {
    text-align: center;
    padding-top: 40px;
    background-color: #fbfbfe;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 600px;
  }
  
  .home h1 {
    font-size: 30px;
    margin-top: 60px;
    line-height: 1.1;
  }
  
  .home p {
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: -20px;
    white-space: pre-line;
    line-height: 1.5;
  }
}

.password-display {
  margin: 60px auto; /* Centrar horizontalmente */
  padding-top: 25px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  background-color: #ffff;
  max-width: 600px;
  height: auto;
  border-radius: 15px;
  border: solid 0.5px #ddd;
  box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.07);
  transition: transform 0.7s ease-in-out;
}

.password-display:hover {
  transform: scale(1.01);
}

.password-display h2 {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 30px;
}

.password-box {
  display: flex; /* Utiliza flexbox */
  align-items: center; /* Centrar verticalmente */
  justify-content: space-between; /* Alinear el contenido en los extremos */
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 15px;
  border: solid 0.5px #dddddd4a;
  background-color: #fbfbfe;
  font-family: monospace;
  font-size: 30px;
  word-break: break-all;
  min-height: 80px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left; /* Asegura que el texto esté alineado a la izquierda */
}

.password-text {
  flex-grow: 1; /* Asegura que el texto tome el espacio disponible */
}

.button-container {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}

.generate-button, .copy-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate-button img, .copy-button img {
  width: 24px;
  height: 24px;
}

.generate-button:hover img, .copy-button:hover img {
  color: #3c9a50;
}

@media (max-width: 1000px) {
  .password-display {
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
    background-color: #ffff;
    max-width: 600px;
    height: auto;
    border-radius: 15px;
    border: solid 0.5px #ddd;
    box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.07);
    transition: transform 0.7s ease-in-out;
  }

  .password-display h2 {
    font-size: 17px;
    margin-bottom: 15px;
  }

  .password-box {
    display: flex; /* Utiliza flexbox */
    align-items: center; /* Centrar verticalmente */
    justify-content: space-between; /* Alinear el contenido en los extremos */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    border: solid 0.5px #dddddd4a;
    background-color: #fbfbfe;
    font-family: monospace;
    font-size: 20px;
    word-break: break-all;
    min-height: 80px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: left; /* Asegura que el texto esté alineado a la izquierda */
  }

  .button-container {
    display: flex;
    gap: 5px;
    margin-left: 10px;
  }

  .generate-button img, .copy-button img {
    width: 20px;
    height: 20px;
  }
  
}




.customize-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  text-align: left; /* Asegura que las etiquetas de los checkboxes estén alineadas a la izquierda */
}

.customize-options label {
  display: flex;
  align-items: center;
  font-size: 17px;
}

.customize-options input[type="checkbox"] {
  display: none;
}

.customize-options input[type="checkbox"] + span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fbfbfe;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
}

.customize-options input[type="checkbox"]:checked + span {
  background-color: #51C764;
  border: 2px solid #51C764;
}

.customize-options input[type="checkbox"]:checked + span::after {
  content: '';
  display: block;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 0px; /* Ajusta la posición vertical */
  left: 4px; /* Ajusta la posición horizontal */
  transform: rotate(45deg);
}


/* Estilos generales para el contenedor del deslizante */
.slider-container {
  margin-top: 40px;
  text-align: left;
}

.slider-container label {
  font-size: 16px;
}

.slider-container input[type="range"] {
  -webkit-appearance: none; /* Elimina el estilo por defecto de WebKit */
  width: 100%;
  margin-top: 20px;
  background: transparent; /* Elimina el fondo por defecto */
}

/* Estilo de la pista del deslizante */
.slider-container input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, #51C764 0%, #51C764 var(--value), #ddd var(--value), #ddd 100%);
  border-radius: 5px;
}

.slider-container input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, #51C764 0%, #51C764 var(--value), #ddd var(--value), #ddd 100%);
  border-radius: 5px;
}

/* Estilo del pulgar del deslizante */
.slider-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Elimina el estilo por defecto de WebKit */
  width: 20px;
  height: 20px;
  background: #51C764;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #51C764;
  margin-top: -6px; /* Ajusta la posición vertical del pulgar */
}

.slider-container input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #51C764;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #51C764;
}

/* Cambia el color del pulgar al pasar el cursor sobre él */
.slider-container input[type="range"]::-webkit-slider-thumb:hover {
  background: #3c9a50;
  border: 2px solid #3c9a50;
}

.slider-container input[type="range"]::-moz-range-thumb:hover {
  background: #3c9a50;
  border: 2px solid #3c9a50;
}



/* MENU ------------------------------------------------------------------------------------------------- */

.menu {
  background-color: #FBFBFE;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 120px;
}

.menu2 {
  height: auto;
  background-color: #fbfbfe;
  margin-left: 0%;
  padding: 0px;
}

.menu2-content {
  margin-bottom: 30px;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.menu2-content h2 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  color: #070817;
  margin-bottom: 50px;
}

.menu2-content-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Espacio entre columnas */
}

.menu2-content-container-column {
  background-color: #F4F6F8;
  border-radius: 15px;
  padding: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  transition: transform 0.3s ease-in-out;
  flex: 1; /* Permite que las columnas se expandan uniformemente */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinea el contenido al inicio de la columna */
}

.menu2-content-container-column h3 {
  font-weight: 600;
  text-align: left;
  font-size: 18px;
  margin-top: 20px; /* Espacio entre el ícono y el título */
}

.menu2-content-container-column p {
  text-align: left;
  font-size: 16px;
  color: #333;
  margin-top: 0px;
}

.menu2-content-container-column:hover {
  transform: scale(1.01);
}

.sellcar_icon {
  filter: invert(70%) sepia(10%) saturate(2226%) hue-rotate(78deg) brightness(93%) contrast(93%);
  width: 50px;
  align-self: flex-start; /* Alinea el ícono al inicio de la columna */
}


@media (max-width: 1000px) {

  .menu2-content {
    margin-bottom: 20px;
    max-width: 1350px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 50px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .menu2-content-container {
    flex-direction: column;
    align-items: center;
  }
  
  .menu2-content-container-column {
    background-color: #F4F6F8;
    border-radius: 15px;
    padding: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    transition: transform 0.3s ease-in-out;
    flex: 2; /* Permite que las columnas se expandan uniformemente */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alinea el contenido al inicio de la columna */
  }

}





/* FAQS ------------------------------------------------------------------------------------------------- */


.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 60px;
}

.faq-container h2 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  color: #070817;
  margin-bottom: 50px;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  background-color: #F4F6F8;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  color: #333;
  text-align: left;
}

.faq-question:hover {
  color: #51C764;
}

.faq-answer {
  background-color: #FBFBFE;
  padding: 15px;
  border-radius: 8px;
  margin-top: 5px;
  font-size: 16px;
  color: #333;
  text-align: left;
}

@media (max-width: 1000px) {
  .faq-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .faq-container h2 {
    font-size: 22px;
    margin-bottom: 30px;
  }

}






.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.terms-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #222;
}

.terms-container h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
}

.terms-container p {
  font-size: 1em;
  margin-bottom: 20px;
}

.terms-container a {
  color: #007BFF;
  text-decoration: none;
}

.terms-container a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .terms-container {
      padding: 15px;
      margin-top: 80px;
  }

  .terms-container h1 {
      font-size: 2em;
  }

  .terms-container h2 {
      font-size: 1.5em;
  }
}




.policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.policy-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #222;
}

.policy-container h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
}

.policy-container p {
  font-size: 1em;
  margin-bottom: 20px;
}

.policy-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.policy-container li {
  margin-bottom: 10px;
}

.policy-container a {
  color: #007BFF;
  text-decoration: none;
}

.policy-container a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .policy-container {
      padding: 15px;
      margin-top: 80px;
  }

  .policy-container h1 {
      font-size: 2em;
  }

  .policy-container h2 {
      font-size: 1.5em;
  }
}





.cookie-consent {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(227, 227, 227, 0.8);
  color: #fff;
  text-align: center;
  padding: 15px;
  z-index: 1000;
}

.cookie-consent p {
  margin: 0;
  display: inline;
  font-size: 14px;
}

.cookie-consent a {
  color: #51C764;
  text-decoration: underline;
}

.cookie-consent button {
  background-color: #51C764;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}

.cookie-consent button:hover {
  background-color: #3c9a50;
}





/* FOOTER ------------------------------------------------------------------------------------------------- */

.footer {
  background-color: #ffff;
  text-align: left;
  max-width: 100%;
  margin-top: 30px;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  height: 250px;
  align-items: start;
}

.footer-column {
  flex: 1;
  height: 150px;
  padding: 20px;
}

.footer-logo {
  height: 40px;
  margin-top: 5px;
}

.footer-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px; /* Aporta espacio entre el título y el contenido */
  margin-top: 0px;
}

.footer-links {
  list-style: none; /* Elimina los puntos por defecto */
  padding: 0;
  margin: 0;
}

.footer-link {
  text-decoration: none;
  display:flex;
  margin-bottom: 10px;
}

.footer-link:hover {
  color: #51C764;
}

.footer-contact {
  font-size: 14px;
  margin-top: 0px;
}

.footer-copyright {
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column;
    height: auto;
  }

  .footer-column {
    width: 100%; /* Ocupa todo el ancho */
    padding: 20px; /* Reduce el padding */
  }

  .footer-title {
    font-size: 14px; /* Reduce el tamaño de la fuente */
  }

  .footer-contact {
    font-size: 12px; /* Reduce el tamaño de la fuente */
  }

  .footer-link {
    font-size: 14px; /* Reduce el tamaño de la fuente */
  }

  .footer-logo {
    height: 30px; /* Reduce el tamaño del logo */
  }

  .footer-copyright{
    font-size: 10px;
  }

}